import { configureStore } from '@reduxjs/toolkit';

import transactionsReducer from './transactions/transactions';
import balanceReducer from './balance/balance';
import globalReducer from './global/global';

const store = configureStore({
    reducer: {
        transactions: transactionsReducer,
        balance: balanceReducer,
        global: globalReducer
    }
});

export default store;