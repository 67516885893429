import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import Web3 from "web3";

import { SessionStorage, keys } from './constants/constansts';

const initialState = {
	loading: {
		web3Connected: false,
		newBlockListnenerRunned: false
	},
	account: {
		privateKey: "",
		address: "",
		auth: false
	}
};

export const globalSlice = createSlice({
	name: "global",
	initialState,
	reducers: {
		/** @param {{type: string, payload: {status: boolean, message: string}}} action */
		setWeb3Connected: (state, action) => {
			state.loading.web3Connected = action.payload;
		},
		setWeb3BlockRunned: (state, action) => {
			state.loading.newBlockListnenerRunned = action.payload;
		},
		setAuthorization: (state, action) => {
			const account = new Web3().eth.accounts.privateKeyToAccount('0x' + action.payload);
			
			state.account.privateKey = action.payload;
			state.account.address = account.address;
			state.account.auth = true;

			sessionStorage.setItem(SessionStorage.privateKey, CryptoJS.AES.encrypt(action.payload, keys.client_aes))
		},
		chechAuthorization: (state) => {
			const privateKey = sessionStorage.getItem(SessionStorage.privateKey);
			if (!privateKey) {
				state.account.auth = false;
			}
		}
	}
});

export const selectGlobal = (state) => state.global;
export const { setLoading, setWeb3Connected, setWeb3BlockRunned } = globalSlice.actions;
export default globalSlice.reducer;