import Web3 from "web3";
import { useDispatch } from "react-redux";
import { Notify, Loading } from "notiflix";

import store from "../store/store";
import Web3Controller from "../libs/web3.controller";
import { setInfo, appendTransaction } from "../store/transactions/transactions";
import { appendLog, appendChange, updateListener } from "../store/balance/balance";
import { setWeb3BlockRunned, setWeb3Connected } from "../store/global/global";

import coinsInHandMP3 from '../assets/audio/coins-in-hand.mp3';
import cashRegisterMP3 from '../assets/audio/cashregister.wav';
import collectcoinMP3 from '../assets/audio/collectcoin.mp3';

export default function useWeb3(rpc='https://bsc-dataseed1.binance.org:443') {
	const dispatch = useDispatch();
	return () => {
		const rpcUrl = localStorage.getItem('rpc-url') || rpc;
		const web3 = new Web3Controller(rpcUrl);
		const web3Prepare = new Web3(rpcUrl);
		web3
			.connected()
			.then(async () => {
				// alert.cashRegister.play().catch(() => {
				// 	console.log("Error with try play sound");
				// });
				dispatch(setWeb3Connected(true));
				const networkID = await web3Prepare.eth.net.getId();
				const alert = {
					coins: new Audio(networkID === 56 ? coinsInHandMP3 : collectcoinMP3),
					cashRegister: new Audio(cashRegisterMP3)
				}
				//transaction hook
				web3.subcrible("newBlock", async (block) => {
					dispatch(setWeb3BlockRunned(true));
					const { transactions } = store.getState();
					dispatch(setInfo({
						block: block.number,
						time: new Date().toLocaleTimeString(),
					}))
					transactions.filters.forEach(async (filter, position) => {
					for (const tr of block.transactions) {
						const target =
						tr.from?.toLowerCase() === filter.address.toLowerCase() ||
						tr.to?.toLowerCase() === filter.address.toLowerCase();
						if (!target) continue;
						web3.eth
						.getTransaction(tr.hash, {
							eth_call: true,
							check_by_address: filter.address.toLowerCase(),
						})
						.then((transaction) => {
							const hasOutTransaction =
							!filter.ignore.out &&
							!!transaction.out &&
							!filter.ignore.addressList.includes(
								transaction.out.address
							);
							const hasInTransaction =
							!!transaction.in &&
							!filter.ignore.addressList.includes(transaction.in.address);
							if (hasOutTransaction) {
							dispatch(appendTransaction({
								...transaction.out,
								type: "OUT",
								hash: tr.hash,
								filter: filter.title || `Filter: #${position + 1}`,
								time: new Date().toLocaleTimeString(),
								networkID
							}))
							}
							if (hasInTransaction) {
							dispatch(appendTransaction({
								...transaction.in,
								type: "IN",
								hash: tr.hash,
								filter: filter.title || `Filter: #${position + 1}`,
								time: new Date().toLocaleTimeString(),
								networkID
							}));
							}
							if (
							filter.notify.sound.checked &&
							(hasInTransaction || hasOutTransaction)
							) {
							alert.coins.play().catch(() => {
								console.log("Error with try play sound");
							});
							}
						})
						.catch((_) => { });
					}
					});
				});
				//balance hook
				new Promise(async () => {
					while (true) {
						const { balance } = store.getState();
						for (let index = 0; index < balance.listeners.length; index++) {
							const listener = balance.listeners[index];
							const min = parseFloat(listener.min);
							const max = parseFloat(listener.max);
							const title = listener.title
								? listener.title
								: `Balance: #${index + 1}`;
							const { contract, address, balance: current } = listener;
							if (!contract || !address) continue;

							await web3.eth.balanceOf({ eth_call: true, contract, address })
								.then((value) => {
									if ((!min && !max && current !== value) || (!!min && value <= min) || (!!max && value >= max)) {
										dispatch(appendChange({ title, contract, balance: value, time: new Date().toLocaleTimeString(), networkID }))
										dispatch(updateListener({ id: index, data: { balance: value } }));
										alert.cashRegister.play()
											.catch(() =>
												dispatch(appendLog({
													time: new Date().toLocaleTimeString(),
													message: 'Error with try play sound',
												})))
									}
								})
								.catch((err) => {
									console.log(err.message);
									dispatch(appendLog({
										time: new Date().toLocaleTimeString(),
										message: err.message
									}));
								})
						}
						await new Promise(r => setTimeout(r, Number(localStorage.getItem('b-interval')) || 500));
					}
				})
			})
			.catch(() => {
				Notify.failure("Error with try connection to blockchain", {
					position: "right-bottom",
				});
				Loading.remove();
			})
	};
}