import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Loading } from "notiflix";

import Home from "./components/Home/Home";
import Balance from "./components/Balance/Balance";
import Transactions from "./components/Transactions/Transactions";

import useWeb3 from "./hooks/web3";
import { selectGlobal } from "./store/global/global";

import './styles/index.scss';

function App() {
  const global = useSelector(selectGlobal);
  const web3 = useWeb3();

  useEffect(() => {
    Loading.circle('Loading...');
    web3();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (global.loading.web3Connected && !global.loading.newBlockListnenerRunned) {
      Loading.change('Get Latest Block...');
    }
    if (global.loading.web3Connected && global.loading.newBlockListnenerRunned) {
      Loading.remove();
    }
  }, [global.loading]);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/transactions" element={<Transactions />} />
        <Route exact path="/balance" element={<Balance />} />
        <Route exact path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
