/**
 * @param {(data: string, file: File)} callback 
 */
export function loadFile(callback) {
	return (event) => {
		if (event.target.files){
			let file = event.target.files[0];
			if (!file) return;
			let reader = new FileReader();
			reader.onload = () => {
				try{
					if (typeof reader.result == 'string'){
						callback(reader.result, file);
					}
				}catch(read_eror){}
			};
			reader.readAsText(file)
		};
	}
}
/**
 * @param {string} value
 * @param {{filename: string, filetype: string}} options
 */
export function saveFile(value, options) {
	return () => {
		const { filename, filetype } = options;
		let file = new Blob([value], {type: filetype});
		let a = document.createElement("a"), url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout( () => {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);  
		}, 0); 
	}
}