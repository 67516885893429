import React, { useState } from 'react';

import './Slider.scss';

const Slider = ({
    onChange,
    checked,
    setChecked,
    name=""
}) => {
    const [localChecked, setLocalChecked] = useState(false);
    return (
        <label className="switch">
            <input
                checked={
                    typeof checked !== 'undefined' ? checked : localChecked
                }
                type="checkbox"
                className='checkbox'
                onChange={(event) => {
                    event.target.name = name;
                    setChecked
                        ? setChecked(event.target.checked)
                        : setLocalChecked(event.target.checked)
                    onChange && onChange(event);
                }}/>
            <span className="slider" />
        </label>
    )
}

export default Slider;