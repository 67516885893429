import React from 'react';

import './Navbar.scss';

const Navbar = ({ children }) => {
	return (
		<div>
			<div className="navbar">
				<div className="brand-title">
					<h4><span className='brand-title__colored'>Sonar</span></h4>
				</div>
				<div className="divider" />
			</div>
			<div className="navbar-sub">
				<div className="navbar-sub__menu">
					<a href='/#/' className='navbar-sub__menu-item'>Home</a>
					<a href='/#/transactions' className='navbar-sub__menu-item'>Transactions</a>
					<a href='/#/balance' className='navbar-sub__menu-item'>Balance</a>
				</div>
			</div>
		</div>
	)
};

export default Navbar;
