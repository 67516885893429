import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Navbar from "../Navbar/Navbar";
import { loadFile, saveFile } from "../../helpers/files";
import reduction from "../../helpers/reduction";
import Slider from "../common/Slider/Slider";
import {
	createFilter,
	removeFilter,
	selectTransactions,
	setFilters,
	updateFilter as trUpdateFilter,
} from "../../store/transactions/transactions";

import "./Transactions.scss";

const Transactions = () => {
	const dispath = useDispatch();
	const state = useSelector(selectTransactions);
	const appState = useRef({ ...state });
	const configLoader = useRef();
	useEffect(() => (appState.current = { ...state }), [state]);

	return (
		<div>
			<Navbar />
			<header>
				<h5 className="center">
					NextScan [Latest Block: {state.info.block} | Time At:{" "}
					{state.info.time} ]
				</h5>
			</header>
			<main className="wrapper">
				<article className="logging">
					<table>
						<thead>
							<tr>
								<td>Filter</td>
								<td>Time</td>
								<td>Name</td>
								<td>Type</td>
								<td>Value</td>
								<td>Chart</td>
								<td>TR</td>
							</tr>
						</thead>
						<tbody>
							{state.transactions.map((transaction, pos) => {
								return (
									<tr key={pos}>
										<td>{reduction(transaction.filter, 10)}</td>
										<td>{transaction.time}</td>
										<td>{`${reduction(transaction.name, 10)} (${reduction(
											transaction.symbol,
											6
										)})`}</td>
										<td>{transaction.type}</td>
										<td>{reduction(transaction.value, 9)}</td>
										<td>
											<a
												href={
													transaction.networkID === 56 ? `https://poocoin.app/tokens/${transaction.address}` 
													: transaction.networkID === 8453 ? `https://dexscreener.com/base/${transaction.address}`
													: `https://www.dexview.com/eth/${transaction.address}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												URL
											</a>
										</td>
										<td>
											<a
												href={
													transaction.networkID === 56 ? `https://bscscan.com/tx/${transaction.hash}` : 
													transaction.networkID === 8453 ? `https://basescan.org/tx/${transaction.hash}` :
													`https://etherscan.io/tx/${transaction.hash}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												URL
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</article>
				<aside className="filters">
					<button
						className="center modern-btn transparent"
						onClick={() => dispath(createFilter())}
					>
						Append Filter
					</button>
					<ul className="modern-list">
						{state.filters.map((filter, position) => {
							const updateFilter = (value) =>
								dispath(trUpdateFilter({ id: position, data: value }));

							const inputHandler = (event) => {
								const { name, value } = event.target;
								switch (name) {
									case "address":
										updateFilter({ address: value });
										break;
									case "title":
										updateFilter({ title: value });
										break;
									case "ignore-address":
										updateFilter({
											ignore: {
												...filter.ignore,
												addressList: value
													.split("\n")
													.map((address) => address.toLowerCase()),
											},
										});
										break;
									default:
										break;
								}
							};
							const checkboxHandler = (event) => {
								const { name, checked } = event.target;
								switch (name) {
									case "sound":
										updateFilter({
											notify: { ...filter.notify, sound: { checked } },
										});
										break;
									case "out":
										updateFilter({
											ignore: { ...filter.ignore, out: checked },
										});
										break;
									default:
										break;
								}
							};
							const title = filter.title
								? filter.title
								: `Filter: #${position + 1}`;
							return (
								<li key={position} className="modern-list__item">
									<details className="accordion">
										<summary className="accordion__header">
											<span>
												{reduction(title, 11)}{" "}
												<span
													className="active-hover"
													onClick={() => dispath(removeFilter(position))}
												>
													[X]
												</span>
											</span>
										</summary>
										<div className="accordion__body">
											<p>
												Adress:{" "}
												<input
													type="text"
													name="address"
													value={filter.address}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Title:{" "}
												<input
													type="text"
													name="title"
													value={filter.title}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Ignore OUT:{" "}
												<Slider
													name="out"
													checked={filter.ignore.out}
													onChange={checkboxHandler}
												/>
											</p>
											<p>
												Play Sound:{" "}
												<Slider
													name="sound"
													checked={filter.notify.sound.checked}
													onChange={checkboxHandler}
												/>
											</p>
											<p>
												Ignore:{" "}
												<textarea
													name="ignore-address"
													cols="10"
													rows="10"
													value={filter.ignore.addressList.join("\n")}
													onChange={inputHandler}
												></textarea>
											</p>
										</div>
									</details>
								</li>
							);
						})}
					</ul>
				</aside>
				<div className="settings browser-default">
					<details className="accordion" open={true}>
						<summary className="accordion__header">
							<span>Settings</span>
						</summary>
						<div className="accordion__body">
							<h5>Config:</h5>
							<input
								type="file"
								ref={configLoader}
								style={{ display: "none" }}
								onChange={loadFile((data, file) => {
									const isValidType =
										file.type === "application/json" ||
										file.type === "text/plain";
									if (isValidType) {
										try {
											const filters = JSON.parse(data);
											dispath(setFilters(filters));
										} catch (e) { }
									}
								})}
							/>
							<button
								onClick={() => configLoader.current.click()}
								className="center modern-btn transparent"
							>
								Load
							</button>
							<button
								onClick={saveFile(JSON.stringify(state.filters), {
									filename: `nextscan-transactions-${state.filters.length}.json`,
									filetype: "application/json",
								})}
								className="center modern-btn transparent"
							>
								Save
							</button>
						</div>
					</details>
				</div>
			</main>
		</div>
	);
};

export default Transactions;
