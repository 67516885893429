import { createSlice } from "@reduxjs/toolkit";


/**
 * @interface
 * @type {{
 * title: string,
 * time: string,
 * balance: number,
 * contract: string
 * }}
 */
// eslint-disable-next-line
const IChange = {
	time: new Date().toLocaleTimeString()
}

/**
 * @interface
 * @type {{
 * time: string,
 * message: string
 * }}
 */
// eslint-disable-next-line
const ILogging = {
	time: new Date().toLocaleTimeString()
}

/**
 * @interface
 * @type {{
 * 	address: string,
 * 	title: string,
 * 	contract: string,
 * 	min: string,
 *	max: string,
 * 	balance: number,
 * }}
 */
export const IListener = {
	title: "",
	address: "",
	contract: "",
	min: "",
	max: "",
	balance: NaN,
}

/**
 * @interface
 * @type {{
 * 	listeners: Array<IListener>,
 * 	changes: Array<IChange>,
 * 	logging: Array<ILogging>
 * }}
 */
const initialState = {
	listeners: [],
	changes: [],
	logging: []
};

export const balanceSlice = createSlice({
	name: "balance",
	initialState,
	reducers: {
		/** @param {{type: string, payload: IListener}} action */
		createListener: (state, action) => {
			state.listeners = [...state.listeners, IListener];
		},
		/** @param {{type: string, payload: number}} action */
		removeListener: (state, action) => {
			state.listeners = state.listeners.filter((_, pos) =>
				pos !== action.payload
			);
		},
		/** @param {{type: string, payload: {id: number, data: IListener}}} action */
		updateListener: (state, action) => {
			const { id, data } = action.payload;
			state.listeners = state.listeners.map((listener, pos) =>
				pos === id ? { ...listener, ...data } : listener
			)
		},
		/** @param {{type: string, payload: Array<IListener>} action */
		setListeners: (state, action) => {
			state.listeners = action.payload;
		},
		/** @param {{type: string, payload: IChange}} action */
		appendChange: (state, action) => {
			state.changes = [action.payload, ...state.changes];
		},	
		/** @param {{type: string, payload: ILogging}} action */
		appendLog: (state, action) => {
			state.logging = [action.payload, ...state.logging];
		}
	}
});

export const {
	setListeners,
	updateListener,
	createListener,
	removeListener,
	appendChange,
	appendLog
} = balanceSlice.actions;

export const selectBalance = (state) => state.balance;

export default balanceSlice.reducer;
