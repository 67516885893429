import Web3 from "web3";

class Eth {
	/** @type {Web3} */
	#web3;

	/**
	 * @param {{address: string, contract: string, eth_call: boolean}} options 
	 * @returns {Promise<number>}
	 */
	async balanceOf(options) {
		const contract = new this.#web3.eth.Contract([
			{
				inputs: [{ internalType: "address", name: "", type: "address" }],
				name: "balanceOf",
				outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
				stateMutability: "view",
				type: "function",
			},
			{
				inputs: [],
				name: "decimals",
				outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
				stateMutability: "view",
				type: "function",
			},
		], options.contract);
		let balance = Number(await contract.methods.balanceOf(options.address).call());
		if (options.eth_call) {
			const decimals = Number(await contract.methods.decimals().call());
			balance *= 10 ** -decimals;
		}

		return balance;
	}
	/**
	 * @param {string} hash
	 * @param {{eth_call: boolean, check_by_address: string}} options
	 * @returns {Promise<{
	 * 	hash: string,
	 *  from: string,
	 *  to: string,
	 *  blockNumber: Number,
	 *  in?: {
	 *			address: string,
	 *          value: number
	 * 			symbol?: string | null,
	 *  		decimals?: number | null,
	 *  		name?: string | null
	 * 		},
	 *  out?: {
	 *			address: string,
	 *          value: number
	 * 			symbol?: string | null,
	 *  		decimals?: number | null,
	 *  		name?: string | null
	 * 		}
	 * }>}
	 */
	async getTransaction(hash, options) {
		const defaultOptions = {
			eth_call: false,
			check_by_address: "",
		};
		options = { ...defaultOptions, ...options };

		const transaction = await this.#web3.eth.getTransactionReceipt(hash);
		const transferTopic = this.#web3.utils.keccak256(
			"Transfer(address,address,uint256)"
		);
		const transfers = transaction.logs.filter(
			(tr) => tr.topics[0] === transferTopic
		);

		const transferOUT = options.check_by_address
			? [...transfers].filter(
				(tr) =>
					this.decodeAddress(tr.topics[1]) ===
					options.check_by_address.toLocaleLowerCase()
			)[0]
			: [...transfers][0];
		const transferIN = options.check_by_address
			? [...transfers]
				.filter(
					(tr) =>
						this.decodeAddress(tr.topics[2]) ===
						options.check_by_address.toLocaleLowerCase()
				)
				.slice(-1)
				.pop()
			: [...transfers].slice(-1).pop();
		
		if (!transferOUT && !transferIN) throw new Error('Transaction don`t contain transfers');
		let result = {
			hash,
			from: transaction.from,
			to: transaction.to,
			blockNumber: transaction.blockNumber,
		}
		if (transferOUT) {
			result.out = {
			  address: transferOUT.address.toLowerCase(),
			  value: this.fromWeiDemicals(this.#web3.utils.hexToNumberString(transferOUT.data), 18)
			}
			if (options.eth_call) {
			  const token = await this.tokenData(result.out.address);
			  result.out.value = this.fromWeiDemicals(this.#web3.utils.hexToNumberString(transferOUT.data), token.decimals);
			  result.out = { ...result.out, ...token };
			}
		}
		if (transferIN) {
			result.in = {
			  address: transferIN.address.toLowerCase(),
			  value: this.fromWeiDemicals(this.#web3.utils.hexToNumberString(transferIN.data), 18)
			}
			if (options.eth_call) {
			  const token = await this.tokenData(result.in.address);
			  result.in.value = this.fromWeiDemicals(this.#web3.utils.hexToNumberString(transferIN.data), token.decimals);
			  result.in = { ...result.in, ...token };
			}
		  }
		return result;
	}

	/**
	 * @param {string} address
	 * @returns {Promise<{
		* symbol: string | null,
		* decimals: number | null,
		* name: string | null
	 * }>}
	*/
	async tokenData(address) {
		const contract = new this.#web3.eth.Contract(
			[
				{
					inputs: [],
					name: "symbol",
					outputs: [{ internalType: "string", name: "", type: "string" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "decimals",
					outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
					stateMutability: "view",
					type: "function",
				},
				{
					inputs: [],
					name: "name",
					outputs: [{ internalType: "string", name: "", type: "string" }],
					stateMutability: "view",
					type: "function",
				},
			],
			address
		);

		return {
			symbol: await contract.methods
				.symbol()
				.call()
				.catch((e) => null),
			decimals: Number(
				await contract.methods
					.decimals()
					.call()
					.catch((e) => undefined) || null
			),
			name: await contract.methods
				.name()
				.call()
				.catch((e) => null),
		};
	}

	/**
  
	   * @param {string} address 
	   * @returns {string}
	  */
	decodeAddress(address) {
		return this.#web3.eth.abi
			.decodeParameter("address", address)
			.toLocaleLowerCase();
	}

	/**
	 * @param {any} value
	 * @param {number} demicals
	 * @returns {number}
	 */
	fromWeiDemicals(value, demicals) {
		if (demicals === 0) return Number(value);
		for (let unit in this.#web3.utils.unitMap) {
			const unitSize = this.#web3.utils.unitMap[unit].length - 1;
			if (unitSize === demicals)
				return Number(this.#web3.utils.fromWei(value.toString(), unit));
		}
		return Number(this.#web3.utils.fromWei(value.toString(), "ether"));
	}

	/**
	 * @constructor
	 * @param {Web3} web3
	 */
	constructor(web3) {
		this.#web3 = web3;
	}
}

class Web3Controller {
	#web3;
	/** @type {Array<Function>} */
	#newBlockListeners;
	/** @type {Boolean} */
	#destroyed;

	/**
	 * @param {Number} ms
	 * @returns {Promise<void>}
	 */
	async #asyncSleep(ms) {
		return new Promise((r) => setTimeout(r, ms));
	}

	async #newBlockListener() {
		this.#newBlockListeners = [];
		let current;
		while (!this.#destroyed) {
			try {
				const callbacks = this.#newBlockListeners.filter((c) => c !== undefined);
				if (!current) {
					current = await this.#web3.eth.getBlockNumber();
				}
				if (callbacks.length) {
					const latest = await this.#web3.eth.getBlockNumber();
					if (current < latest) {
						for (let blockNumber = current + 1; blockNumber <= latest;) {
							const block = await this.#web3.eth.getBlock(blockNumber, true);
							if (!block) continue;
							for (const callback of callbacks) {
								new Promise((r) => {
									if (!callback) return r();
									return r(callback(block));
								});
							}
							blockNumber += 1;
						}
						current = latest;
					}
				}
				await this.#asyncSleep(500);
			} catch (err) {
				console.log(`[!]ETH LISTENER ERROR: ${err.message}`);
			}
		}
	}

	/**
	 * @param {"newBlock" | "test"} topic
	 * @param {Function} callback
	 */
	subcrible(topic, callback) {
		/** @type {Number} */
		let subcribleID;
		switch (topic) {
			case "newBlock":
				subcribleID = this.#newBlockListeners.length;
				this.#newBlockListeners.push(callback);
				return {
					unSubscrible: () => {
						this.#newBlockListeners.filter((_, pos) => pos !== subcribleID);
					},
					/** @param {Function} callback */
					changeCallback: (callback) => {
						this.#newBlockListeners[subcribleID] = callback;
					},
					subscribleID: subcribleID,
				};
			default:
				throw new Error("Invalid subcrible topic");
		}
	}

	get newBlockListeners() {
		return this.#newBlockListeners;
	}

	/** @param {Array<Function>} callback */
	set newBlockListeners(callbacksList) {
		this.#newBlockListeners = callbacksList;
	}

	destroy() {
		this.#destroyed = true;
	}

	setProvider(url) {
		this.#web3.setProvider(url)
	}

	async connected() {
		return await this.#web3.eth.getChainId().then(() => true).catch(() => false);
	}

	/**
	 * @constructor
	 * @param {string} provider
	 */
	constructor(provider) {
		this.#web3 = new Web3(provider);
		this.eth = new Eth(this.#web3);
		this.#destroyed = false;
		//start class listeners
		this.#newBlockListener();
	}
}

export default Web3Controller;
