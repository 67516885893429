export default function reduction(target, size = 6, symbol = '.', symbolCount = 3) {
	const symbols = symbol.repeat(symbolCount);

	if (typeof target !== 'string') {
		target = target.toString();
	}
	if (!isNaN(Number(target)) && target.toString().length > size && Number(target).toExponential().toString().length < size) {
		target = Number(target).toExponential();
	}
	if (target.length > size) {
		target = target.slice(0, size) + symbols
	};

	return target;
}