import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Navbar from "../Navbar/Navbar";
import { loadFile, saveFile } from "../../helpers/files";
import reduction from "../../helpers/reduction";
import {
	selectBalance,
	setListeners,
	updateListener as updateListenerAction,
	createListener,
	removeListener,
} from '../../store/balance/balance';

import "./Balance.scss";

const Balance = () => {
	const dispath = useDispatch();
	const state = useSelector(selectBalance);
	const appState = useRef({ ...state });
	const configLoader = useRef();
	useEffect(() => (appState.current = { ...state }), [state]);

	useEffect(() => {
		console.log(state);
	}, [state])

	return (
		<div>
			<Navbar />
			<header>
				<h5 className="center">NextScan [ Balance Listener ]</h5>
			</header>
			<main className="wrapper">
				<div className="info">
					<article className="changes">
						<table>
							<thead>
								<tr>
									<td>Title</td>
									<td>Time</td>
									<td>Balance</td>
									<td>Chart</td>
								</tr>
							</thead>
							<tbody>
								{state.changes.map((change, pos) => {
									return (
										<tr key={pos}>
											<td>{reduction(change.title, 12)}</td>
											<td>{change.time}</td>
											<td>{reduction(change.balance, 20)}</td>
											<td>
												<a
													href={
														change.networkID === 56 ? `https://poocoin.app/tokens/${change.contract}` : 
														change.networkID === 8453 ? `https://dexscreener.com/base/${change.contract}` : 
														`https://www.dexview.com/eth/${change.contract}`
													}
													target="_blank"
													rel="noopener noreferrer"
												>
													URL
												</a>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</article>
					<article className="logging">
						<table className="logging__table">
							<thead>
								<tr>
									<td>Time</td>
									<td>Message</td>
								</tr>
							</thead>
							<tbody>
								{state.logging.map((log, pos) => {
									return (
										<tr key={pos}>
											<td className="logging__table-time">{log.time}</td>
											<td className="logging__table-message">{log.message}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</article>
				</div>
				<aside className="filters">
					<button
						className="center modern-btn transparent"
						onClick={() => dispath(createListener())}
					>
						Append Listener
					</button>
					<ul className="modern-list">
						{state.listeners.map((listener, position) => {
							const updateListener = (value) =>
								dispath(updateListenerAction({ id: position, data: value }));

							const inputHandler = (event) => {
								const { name, value } = event.target;

								const numberValidator = (val) => {
									const numberPattern = /[^0-9.e-]/g;
									return val.replace(',', '.').replace(numberPattern, '');
								} 
								switch (name) {
									case "address":
										updateListener({ address: value });
										break;
									case "title":
										updateListener({ title: value });
										break;
									case "contract":
										updateListener({ contract: value });
										break;
									case "max":
										updateListener({ max: numberValidator(value) });
										break;
									case "min":
										updateListener({ min: numberValidator(value) });
										break;
									default:
										break;
								}
							};
							console.log(listener);
							const title = listener.title
								? listener.title
								: `Balance: #${position + 1}`;
							return (
								<li key={position} className="modern-list__item">
									<details className="accordion">
										<summary className="accordion__header">
											<span>
												{reduction(title, 11)}{" "}
												<span
													className="active-hover"
													onClick={() => dispath(removeListener(position))}
												>
													[X]
												</span>
											</span>
										</summary>
										<div className="accordion__body">
											<p>
												Adress:{" "}
												<input
													type="text"
													name="address"
													value={listener.address}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Contract:{" "}
												<input
													type="text"
													name="contract"
													value={listener.contract}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Title:{" "}
												<input
													type="text"
													name="title"
													value={listener.title}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Min: {" "}
												<input
													type="text"
													name="min"
													value={listener.min}
													onChange={inputHandler}
												/>
											</p>
											<p>
												Max: {" "}
												<input
													type="text"
													name="max"
													value={listener.max}
													onChange={inputHandler}
												/>
											</p>
										</div>
									</details>
								</li>
							);
						})}
					</ul>
				</aside>
				<div className="settings browser-default">
					<details className="accordion" open={true}>
						<summary className="accordion__header">
							<span>Settings</span>
						</summary>
						<div className="accordion__body">
							<h5>Config:</h5>
							<input
								type="file"
								ref={configLoader}
								style={{ display: "none" }}
								onChange={loadFile((data, file) => {
									const isValidType =
										file.type === "application/json" ||
										file.type === "text/plain";
									if (isValidType) {
										try {
											const listeners = JSON.parse(data);
											dispath(setListeners(listeners));
										} catch (e) { }
									}
								})}
							/>
							<button
								onClick={() => configLoader.current.click()}
								className="center modern-btn transparent"
							>
								Load
							</button>
							<button
								onClick={saveFile(JSON.stringify(state.listeners), {
									filename: `nextscan-balance-${state.listeners.length}.json`,
									filetype: "application/json",
								})}
								className="center modern-btn transparent"
							>
								Save
							</button>
						</div>
					</details>
				</div>
			</main>
		</div>
	);
};

export default Balance;
