import React, { useEffect, useState } from "react";

import Navbar from "../Navbar/Navbar";

const Home = () => {
  const [rpc, setRpc] = useState(localStorage.getItem('rpc-url') || "https://bsc-dataseed1.binance.org");
  const [bInterval, setBInterval] = useState(Number(localStorage.getItem('b-interval')) || 500);

  useEffect(() => {
	localStorage.setItem('rpc-url', rpc);
  }, [rpc]);

  useEffect(() => {
	localStorage.setItem('b-interval', bInterval);
  }, [bInterval]);

  return (
    <div>
      <Navbar />
      <div class="container">
        <main className="">
          <div class="row">
            <div class="col s12">
              <h1>In development...</h1>
              <p>Remark 2022, </p>
            </div>
            <div class="col s12">
              <h3>Base Settings:</h3>
			  <p>Reftesh page for apply changes</p>
              <p>
                Rpc: <input type="text" value={rpc} onChange={(event) => {
					setRpc(event.target.value);
				}}/>
              </p>
              <p>
                Balance interval: <input type="text" value={bInterval} onChange={(event) => {
					setBInterval(event.target.value);
				}} />
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
