import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {{
 * 	filters: Array<{
 * 		address: string,
 * 		title: string,
 * 		ignore: {
 * 			addressList: string[],
 * 			out: boolean
 * 		},
 * 		notify: {
 * 			sound: {checked: boolean}
 * 		}
 * 	}>,
 * 	info: {
 * 		block: string,
 *  	time: string,
 * 	},
 * 	transactions: Array<any>
 * }}
 */
const initialState = {
	info: {
		block: "?",
		time: "?",
	},
	filters: [],
	transactions: [],
};

export const transactionsSlice = createSlice({
	name: "transactions",
	initialState,
	reducers: {
		setInfo: (state, action) => {
			state.info = action.payload;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		createFilter: (state) => {
			const filter = {
				address: "",
				title: "",
				ignore: {
					addressList: [],
					out: false,
				},
				notify: {
					sound: {
						checked: false,
					}
				},
			};
			state.filters = [...state.filters, filter];
		},
		removeFilter: (state, action) => {
			state.filters = [...state.filters].filter(
				(_, id) => id !== action.payload
			);
		},
		updateFilter: (state, action) => {
			const { data, id } = action.payload;
			state.filters = state.filters.map((filter, pos) =>
				pos === id ? { ...filter, ...data } : filter
			);
		},
		appendTransaction: (state, action) => {
			state.transactions = [action.payload, ...state.transactions];
		},
	},
});

export const {
	createFilter,
	removeFilter,
	updateFilter,
	appendTransaction,
	setFilters,
	setInfo,
} = transactionsSlice.actions;

export const selectTransactions = (state) => state.transactions;

export default transactionsSlice.reducer;
